import { ComponentPropsWithoutRef } from 'react';
import { Router, useRouter } from 'next/router';
import { getBaseUrl } from '@/lib/contentful/utils';
import { isDevelopment } from '@/utils/isDevelopment';
import { isNonNullable } from '@/utils/isNonNullable';
import { toTitleCase } from '@/utils/string/toTitleCase';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { Warning } from '@/components/Warning';
function buildAppBreadCrumbs({
  asPath,
  locale
}: {
  asPath: Router['asPath'];
  locale: Router['locale'];
}) {
  return asPath.split('?')?.[0]?.split('/').filter(Boolean)?.map((path, i, arr) => {
    if (i === arr.length - 1) return null;
    if (arr[0] === 'apps' && i === 1 && path === 'categories') return null;
    if (path === 'apps') {
      return {
        text: 'Apps & Integrations',
        link: {
          href: '/apps'
        },
        schemaUrl: `${getBaseUrl(locale)}/apps/`
      };
    }
    if (arr[0] === 'apps' && arr[1] === 'categories' && i === 2) {
      return {
        text: toTitleCase(path),
        link: {
          href: `/apps/categories/${path}/`
        },
        schemaUrl: `${getBaseUrl(locale)}/apps/categories/${path}/`
      };
    }
    if (i === 1 && path === 'collections') {
      return null;
    }
    return {
      text: toTitleCase(path),
      link: {
        href: `/${path}/`
      },
      schemaUrl: `${getBaseUrl(locale)}/${path}/`
    };
  })?.filter(isNonNullable) ?? [];
}
type Props = Omit<ComponentPropsWithoutRef<typeof Breadcrumbs>, 'breadcrumbs' | 'currentPage'>;
export function AppBreadcrumbs(props: Props) {
  const {
    asPath,
    locale
  } = useRouter();
  const isAppPage = asPath.startsWith('/apps');
  if (!isAppPage) {
    if (isDevelopment()) {
      return <Warning className={props.variant === 'dark' ? 'text-white' : ''}>
					AppBreadcrumbs not supported outside of a Marketplace App Page
				</Warning>;
    }
    return null;
  }
  const breadcrumbs = buildAppBreadCrumbs({
    asPath,
    locale
  });
  const currentPage = toTitleCase(asPath.split('?')?.[0]?.split('/').filter(Boolean)?.at(-1) ?? '');
  return <Breadcrumbs {...props} breadcrumbs={[{
    text: 'Home',
    link: {
      href: '/'
    },
    schemaUrl: getBaseUrl(locale)
  }, ...breadcrumbs]} currentPage={currentPage === 'Apps' ? 'Apps & Integrations' : currentPage} data-sentry-element="Breadcrumbs" data-sentry-component="AppBreadcrumbs" data-sentry-source-file="AppBreadcrumbs.tsx" />;
}